import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropertyLabel from '../components/PropertyLabel'
import Box from '../components/Box'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import RemoteActionCustomerSingleAddForm from '../forms/RemoteActionCustomerSingleAddForm'
import RemoteActionFaultAddForm from '../forms/RemoteActionFaultAddForm'
import RemoteActionFaultRepairForm from '../forms/RemoteActionFaultRepairForm'
import RemoteActionBaqueiraRentDaysForm from '../forms/RemoteActionBaqueiraRentDaysForm'
import RemoteActionBaqueiraBlockLockerForm from '../forms/RemoteActionBaqueiraBlockLockerForm'
import RemoteActionBaqueiraClickAndCollectForm from '../forms/RemoteActionBaqueiraClickAndCollectForm'
import RemoteActionBaqueiraWhitelistForm from '../forms/RemoteActionBaqueiraWhitelistForm'
import RemoteActionBaqueiraSeasonForm from '../forms/RemoteActionBaqueiraSeasonForm'

class MatrixConstructor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matrixModalOpen: false,
      matrixMatricula: '',
      currentView: 'main',
      matrixSN: '',
      lockerType: '',
      blockType: null,
      blockedLockers: []
    }

    this.openMatrixDialog = this.openMatrixDialog.bind(this)
    this.closeMatrixDialog = this.closeMatrixDialog.bind(this)
    this.terminateConfirmToggle = this.terminateConfirmToggle.bind(this)
    this.changeView = this.changeView.bind(this)
    this.updateBlockType = this.updateBlockType.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Check if rentals prop has changed
    if (prevProps.rentals !== this.props.rentals) {
      // Flatten all LockerMatricula arrays into a single array of blocked lockers
      const blockedLockers = this.props.rentals.flatMap(rental => rental.LockerMatricula);
      
      // Update state with the blocked lockers
      this.setState({ blockedLockers });
    }
  }

  updateBlockType(newBlockType) {
    this.setState({ blockType: newBlockType });
  }

  terminateConfirmToggle() {
    this.setState({ matrixModalOpen: !this.state.matrixModalOpen })
  }

  openMatrixDialog(matricula, sn, type, block, e) {
    this.setState({ matrixModalOpen: true, matrixMatricula: matricula, currentView: 'main', matrixSN: sn, lockerType: type, blockType: block })
  }

  closeMatrixDialog() {
    this.setState({ matrixModalOpen: false })
  }

  changeView(view) {
    this.setState({ currentView: view });
  }

  getErrorCode(error) {

    switch (error) {
      case '0x00': return 'OK'
      case '0xFF': return 'INITIALIZED'
      case '0x01': return 'CONNECTED'
      case '0x02': return 'ESP32 PROCESS SUCC'
      case '0x03': return 'USER PIN ASSIGNED'
      case '0x04': return 'CONN FAULT'
      case '0x05': return 'SCAN TIMEOUT'
      case '0x06': return 'GEN ERROR 1'
      case '0x07': return 'ESP32 PROCESS UNSUCC'
      case '0x08': return 'GEN ERROR 2'
      case '0x09': return 'GEN ERROR 3'
      case '0x0A': return 'GEN ERROR 4'
      case '0x0B': return 'GEN ERROR 5'
      case '0x0C': return 'USER PIN UNASSIGNED'
      default: return error
    }
  }

  getColorClass(rssi) {
    if (rssi >= -60 && rssi !== 0) {
      return 'HIGHTOP';
    } else if (rssi < -60 && rssi >= -65) {
      return 'HIGH';
    } else if (rssi < -65 && rssi >= -70) {
      return 'HIGHLOW';
    } else if (rssi < -70 && rssi >= -75) {
      return 'MIDHIGH';
    } else if (rssi < -75 && rssi >= -80) {
      return 'MID';
    } else if (rssi < -80 && rssi >= -85) {
      return 'MIDLOW';
    } else if (rssi < -85 && rssi >= -90) {
      return 'LOWHIGH';
    } else if (rssi < -90 && rssi >= -95) {
      return 'LOW';
    } else if (rssi < -95 && rssi >= -100) {
      return 'LOWTOP';
    } else {
      return 'Null';
    }
  }

  infoMatrix() {
    const { currentView, blockType } = this.state;
    const { role, category, refreshMatrix } = this.props;

    let checkMatricula = "";
    let checkBLEName = "";
    let checkHobbit = false;
    let checkBateria = "";
    let checkPin = "";
    let checkPhone = "";
    let checkModule = "";
    let checkPass = "";
    let checkAdmin = "";
    let checkType = "";
    let checkPosition = "";
    let checkStatus = "";
    let checkWide = "";
    let checkMode = "";
    let checkConfigured = false;
    let checkLastNightscan = "";
    let checkRssi = 0;
    let checkGender = '';
    let checkFaultCode = '';
    let checkErrorCode = '';
    let kerongVersion = '';

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (index + 1 == this.state.matrixMatricula) {
              checkMatricula = lock.Matricula || checkMatricula;
              checkType = lock.Type || checkType;
              checkBLEName = lock.BLEName || checkBLEName;
              checkBateria = lock.Bateria || checkBateria;
              checkPin = lock.Pin || checkPin;
              checkPhone = lock.Phone || checkPhone;
              checkModule = lock.ModuleSN || checkModule;
              checkPass = lock.Pass || checkPass;
              checkAdmin = lock.PasswordCash || checkAdmin;
              checkPosition = lock.Position || checkPosition;
              checkStatus = lock.Status || checkStatus;
              checkWide = lock.wide || checkWide;
              checkMode = lock.Mode || checkMode;
              checkConfigured = lock.Configured || checkConfigured;
              checkLastNightscan = lock.LastNightScanCompleted || checkLastNightscan;
              checkRssi = lock.Rssi || checkRssi;
              checkGender = lock.Gender || checkGender;
              checkFaultCode = lock.FaultCode || checkFaultCode;
              checkErrorCode = lock.ErrorBle || checkErrorCode;
              kerongVersion = lock.KerongVersion || kerongVersion;
            }
          })
        }
      })
    }

    const year = parseInt(checkLastNightscan.slice(0, 4));
    const month = parseInt(checkLastNightscan.slice(4, 6)) - 1; // month is zero-indexed
    const day = parseInt(checkLastNightscan.slice(6, 8));
    const hour = parseInt(checkLastNightscan.slice(8, 10));
    const minute = parseInt(checkLastNightscan.slice(10, 12));
    const second = parseInt(checkLastNightscan.slice(12, 14));

    const nightscanDate = new Date(year, month, day, hour, minute, second);

    nightscanDate.setHours(nightscanDate.getHours());
    const formattedDate = `${nightscanDate.getDate()}/${nightscanDate.getMonth() + 1}/${nightscanDate.getFullYear()}`;
    const formattedTime = `${nightscanDate.getHours().toString().padStart(2, '0')}:${nightscanDate.getMinutes().toString().padStart(2, '0')}`;

    const totalBattery = 6500; // Change this to the maximum battery value
    const minBattery = 4400; // Change this to the maximum battery value

    // Calculate the battery percentage
    const batteryPercentage = ((checkBateria - minBattery) / (totalBattery - minBattery)) * 100;

    // Determine the number of squares based on battery level
    let squaresCount;
    let batteryColor;

    if (batteryPercentage >= 83.3) {
      squaresCount = 5;
      batteryColor = 'green';
    } else if (batteryPercentage >= 66.7) {
      squaresCount = 4;
      batteryColor = 'green';
    } else if (batteryPercentage >= 50) {
      squaresCount = 3;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 33.3) {
      squaresCount = 2;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 16.7) {
      squaresCount = 1;
      batteryColor = 'red';
    } else {
      squaresCount = 0;
      batteryColor = 'red';
    }

    if (currentView === 'main') {
      return (
        <CSSTransition key="main" timeout={300} classNames="fade">
          <div>
            <div className="text-break text-monospace">
              <div className="row justify-content-center">
                <div className="col-xs-12 col-md-12 col-lg-4">
                  <Box title="Cliente" icon="user" bgcolor="info" textcolor="white">
                    <PropertyLabel name="Teléfono"
                      value={checkPhone} textcolor="white" />
                    <PropertyLabel name="Pin Cliente"
                      value={checkPass} textcolor="white" />
                    <PropertyLabel name="Pin Universal"
                      value={checkAdmin} textcolor="white" />
                  </Box>
                  <Box title="Potencia (Mejor a Peor)" icon="wifi">
                    <div className="rssi-bar">
                      {['HIGHTOP', 'HIGH', 'HIGHLOW', 'MIDHIGH', 'MID', 'MIDLOW', 'LOWHIGH', 'LOW', 'LOWTOP'].map((color) => (
                        <div key={color} id={`${color}`} className={`rssi-square ${color === this.getColorClass(checkRssi) ? 'highlighted' : ''}`}>
                          {color === this.getColorClass(checkRssi) && <h5>{checkRssi}</h5>}
                        </div>
                      ))}
                    </div>
                  </Box>
                  <Box title="Bateria" icon="battery-full">
                    <div className="battery-indicator">
                      <div className="battery-info">
                        <div className="battery-percentage">{batteryPercentage.toFixed(1)} %</div>
                      </div>
                      <div className="battery-shape">
                        {Array.from({ length: squaresCount }, (_, index) => (
                          <div key={index} className={`battery-square ${batteryColor}`}></div>
                        ))}
                      </div>
                      <div className="battery-info">
                        <div className="battery-number">{(checkBateria / 1000).toFixed(1)} V</div>
                      </div>
                    </div>
                  </Box>
                  <Box title="Errores" icon="exclamation-triangle" bgcolor="danger" textcolor="white">
                    <PropertyLabel name="Avería"
                      value={checkFaultCode} textcolor="white" />
                    <PropertyLabel name="Código Error"
                      value={this.getErrorCode(checkErrorCode)} textcolor="white" />
                  </Box>
                </div>
                {(role === 'ADMIN' || (role === 'CUSTOMER' && category === 'BAQUEIRA')) &&
                  <div className="col-xs-12 col-md-12 col-lg-4">
                    <Box title="Reservas Baqueira" icon="hand-paper">
                      <div className="row">
                        <div className="col col-xs-12 col-md-12 col-lg-6">
                          <Button
                            color="primary"
                            className={`mt-2 mb-2 d-block w-100 ${blockType === null ? 'btn-baqueira' : 'btn-baqueira-blocked'}`}
                            onClick={() => blockType === null && this.changeView('baqueira1')}
                            disabled={blockType !== null} // Optional: disable the button entirely based on blockType
                          >
                            <i className="fas fa-key d-block ml-auto mr-auto mb-2"></i>
                            RESERVA DIAS
                          </Button>
                        </div>
                        <div className="col col-xs-12 col-md-12 col-lg-6">
                          <Button
                            color="success"
                            className={`mt-2 mb-2 d-block w-100 ${blockType === null ? 'btn-baqueira' : 'btn-baqueira-blocked'}`}
                            onClick={() => blockType === null && this.changeView('baqueira2')}
                            disabled={blockType !== null} // Optional: disable the button entirely based on blockType
                          >
                            <i className="fas fa-user-clock d-block ml-auto mr-auto mb-2"></i>
                            CLICK & COLLECT
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col col-xs-12 col-md-12 col-lg-6">
                          <Button
                            color="secondary"
                            className={`mt-2 mb-2 d-block w-100 ${blockType != null ? 'btn-baqueira' : 'btn-baqueira-blocked'}`}
                            onClick={() => blockType !== null && this.changeView('baqueira3')}
                            disabled={blockType === null} // Optional: disable the button entirely based on blockType
                          >
                            <i className="fas fa-stop-circle d-block ml-auto mr-auto mb-2"></i>
                            RESERVA LISTA BLANCA
                          </Button>
                        </div>
                        <div className="col col-xs-12 col-md-12 col-lg-6">
                          <Button
                            color="info"
                            className={`mt-2 mb-2 d-block w-100 ${blockType === null ? 'btn-baqueira' : 'btn-baqueira-blocked'}`}
                            onClick={() => blockType === null && this.changeView('baqueira4')}
                            disabled={blockType !== null} // Optional: disable the button entirely based on blockType
                          >
                            <i className="fas fa-calendar-alt d-block ml-auto mr-auto mb-2"></i>
                            RESERVA TEMPORADA
                          </Button>
                        </div>
                      </div>
                    </Box>
                    <Box title="Acciones Baqueira" icon="hand-paper">
                      <div className="row">
                        <div className="col col-xs-12 col-md-12 col-lg-6">
                          <Button color="warning" className="mt-2 mb-2 d-block w-100 btn-baqueira text-dark" onClick={() => this.changeView('baqueira5')}>
                            <i className="fas fa-ban d-block ml-auto mr-auto mb-2"></i>
                            BLOQUEO LISTAS BLANCAS
                          </Button>
                        </div>
                        <div className="col col-xs-12 col-md-12 col-lg-6">
                          <Button color="dark" className="mt-2 mb-2 d-block w-100 btn-baqueira" onClick={() => this.changeView('baqueira6')}>
                            <i className="fas fa-network-wired d-block ml-auto mr-auto mb-2"></i>
                            OTROS
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </div>
                }
                <div className="col-xs-12 col-md-12 col-lg-4">
                  <Box title="Acciones Remotas" icon="hand-paper">
                    <RemoteActionCustomerSingleAddForm machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} lockerStatus={checkStatus} closeMatrixDialog={this.closeMatrixDialog} />
                    <Button
                        color={`${checkStatus !== 'FL' ? 'danger' : 'success'}`}
                        className={`mt-2 mb-2 d-block w-100`}
                        onClick={() => this.changeView(checkStatus !== 'FL' ? 'faultView' : 'repairFaultView')}
                      >
                        <i className="fas fa-times d-block ml-auto mr-auto mb-2"></i>
                        {checkStatus !== 'FL' ? 'MARCAR AVERÍA' : 'REPARAR AVERÍA'}
                      </Button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )
    }

    // View for "Acciones Baqueira 1" RESERVA POR DIAS
    if (currentView === 'baqueira1') {
      return (
        <CSSTransition key="baqueira1" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Reserva de taquilla por días" icon="hand-paper">
                <RemoteActionBaqueiraRentDaysForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} lockerStatus={checkStatus} closeMatrixDialog={this.closeMatrixDialog} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    // View for "Acciones Baqueira 2" CLICK AND COLLECT
    if (currentView === 'baqueira2') {
      return (
        <CSSTransition key="baqueira2" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Reserva Click & Collect" icon="hand-paper">
                <RemoteActionBaqueiraClickAndCollectForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} lockerStatus={checkStatus} closeMatrixDialog={this.closeMatrixDialog} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    // View for "Acciones Baqueira 3" RESERVA POR TEMPORADA
    if (currentView === 'baqueira3') {
      return (
        <CSSTransition key="baqueira3" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Reserva de Lista Blanca" icon="hand-paper">
                <RemoteActionBaqueiraWhitelistForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} lockerStatus={checkStatus} closeMatrixDialog={this.closeMatrixDialog} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    // View for "Acciones Baqueira 4" RESERVA TEMPORADA
    if (currentView === 'baqueira4') {
      return (
        <CSSTransition key="baqueira4" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Reserva de temporada" icon="hand-paper">
                <RemoteActionBaqueiraSeasonForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} lockerStatus={checkStatus} closeMatrixDialog={this.closeMatrixDialog} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    // View for "Acciones Baqueira 5" BLOQUEO LISTA BLANCA
    if (currentView === 'baqueira5') {
      return (
        <CSSTransition key="baqueira5" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Bloqueo de listas blancas" icon="hand-paper">
                {/* Replace with actual component or content for Acciones Baqueira 3 */}
                <RemoteActionBaqueiraBlockLockerForm blockType={this.state.blockType} lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} lockerStatus={checkStatus} closeMatrixDialog={this.closeMatrixDialog} refreshMatrixData={refreshMatrix} updateBlockType={this.updateBlockType} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    // View for "Acciones Baqueira 6" OTROS
    if (currentView === 'baqueira6') {
      return (
        <CSSTransition key="baqueira6" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Acciones Baqueira 6" icon="hand-paper">
                {/* Replace with actual component or content for Acciones Baqueira 3 */}
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    if (currentView === 'faultView') {
      return (
        <CSSTransition key="faultView" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Marcar avería en taquilla" icon="hand-paper">
                <RemoteActionFaultAddForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} closeMatrixDialog={this.closeMatrixDialog} refreshMatrixData={refreshMatrix} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    if (currentView === 'repairFaultView') {
      return (
        <CSSTransition key="repairFaultView" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Marcar reparación de avería en taquilla" icon="hand-paper">
              <RemoteActionFaultRepairForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} closeMatrixDialog={this.closeMatrixDialog} refreshMatrixData={refreshMatrix} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }
  }

  getBackgroundColor(BlockType) {
    const { articles } = this.props;

    // Ensure articles and articles.Whitelist exist before searching
    if (!articles || !articles.Whitelist) {
      return null;
    }

    // Find the whitelist item by its _id
    const whitelistItem = articles.Whitelist.find(item => item._id === BlockType);

    // If the item is found, return its color; otherwise, return a default value or null
    return whitelistItem ? whitelistItem.color : null;
  }

  getBackgroundName(BlockType) {
    const { articles } = this.props;

    // Ensure articles and articles.Whitelist exist before searching
    if (!articles || !articles.Whitelist) {
      return null;
    }

    // Find the whitelist item by its _id
    const whitelistItem = articles.Whitelist.find(item => item._id === BlockType);

    // If the item is found, return its color; otherwise, return a default value or null
    return whitelistItem ? whitelistItem.name : null;
  }

  render() {
    const { currentView, blockType } = this.state;
    const { category, matrixs, blockedLockers } = this.props;

    const matrixRender = [];
    let lockerSelected = "";
    let lockerStatus = "";
    let matrixIndex = 0;
    let checkKiosc = 0;

    let columnHeight = 0;
    let matrixWidth = 0;

    {
      matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (matrixIndex < this.props.machine.LastIndex) {

              lockerSelected = lock.Mode + lock.Wide + lock.Type

              lock.Configured ? lockerStatus = lock.Status : lockerStatus = "Null"

              lock.Status == "FB" ? lockerStatus = "AV" : lockerStatus = lock.Status

              switch (lock.Type) {

                case "S":
                  columnHeight += 45
                  break;
                case "M":
                  columnHeight += 90
                  break;
                case "L":
                  columnHeight += 135
                  break;
                case "XL":
                  columnHeight += 180
                  break;
                case "2XL":
                  columnHeight += 225
                  break;
                case "3XL":
                  columnHeight += 270
                  break;

              }

              if (columnHeight >= 270) {

                columnHeight = 0;

                if (lock.Wide === 'N') {
                  matrixWidth += 45;
                } else {
                  matrixWidth += 60;
                }
              }

              const isBlocked = blockedLockers.includes(lock.Matricula);

              matrixRender.push(

                <div key={index} id={`locker${lockerSelected}`} className={isBlocked ? 'lockerBlocked' : ''} onClick={e => this.openMatrixDialog(index + 1, lock.BLEName, lockerSelected, lock.BlockType, e)}>
                  <p>{index + this.props.machine.FirstIndex}</p>
                  <div id={lockerStatus}><p>{lockerStatus}</p></div>
                  {lock.BlockType !== null && lock.Type === '3XL' &&
                    <div
                      style={{
                        backgroundColor: this.getBackgroundColor(lock.BlockType),
                        writingMode: 'vertical-rl', // Make the text vertical
                        paddingTop: '10px', // Optional padding for better visibility
                        paddingBottom: '10px', // Optional padding for better visibility
                        display: 'inline-block', // Ensures the block shrinks to fit the text
                        height: 'auto', // Ensures proper height adjustment
                        alignItems: 'center', // Optional centering
                        justifyContent: 'center', // Optional centering
                        fontSize: 'small',
                        borderRadius: '10px',
                        border: '1px solid #ccc'
                      }}>
                      <span>{this.getBackgroundName(lock.BlockType)}</span>
                    </div>
                  }
                </div>
              );

              matrixIndex++

              if (lock.Position === "C" && checkKiosc === 0) {

                columnHeight += 135

                matrixRender.push(

                  <div key={matrixIndex} id="lockerKioskLeft">
                  </div>

                );

                checkKiosc = 1
              } else if (lock.Position === "C" && checkKiosc === 1) {

                columnHeight += 135

                matrixRender.push(

                  <div key={matrixIndex} id="lockerKioskRight">
                  </div>

                );

                checkKiosc = 0
              }
            }
          })
        }
      })
    }

    return (
      <div id="matrixContainer" style={{ width: matrixWidth + 40 }}>
        <div class="row" id="lockerMatrix">
          {matrixRender}
        </div>
        <div>
          <Modal isOpen={this.state.matrixModalOpen} className="locker-modal-admin">
            <ModalHeader toggle={this.terminateConfirmToggle}>
              <div class="text-center ml-5 pl-4">
                <h1>
                  <span className="text-vw-dark">Taquilla </span>
                  <span className="text-vw-light">{Number(this.state.matrixMatricula) + this.props.machine.FirstIndex - 1}</span>
                </h1>
              </div>
              {blockType != null &&
                <div className={`white-list-advice ml-5 pl-4`}
                  style={{
                    backgroundColor: this.getBackgroundColor(blockType)
                  }}>
                  <span>Taquilla bloqueada para: {this.getBackgroundName(blockType)}</span>
                </div>
              }
            </ModalHeader>
            <ModalBody>
              <TransitionGroup>
                {this.infoMatrix()}
              </TransitionGroup>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );

  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  category: state.auth.category
})

export default connect(mapStateToProps)(MatrixConstructor)


